import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How the Nulogy Design Ops team releases packages`}</h1>
    <p>{`When we set out to design our release solution, we had several goals in mind:`}</p>
    <ul>
      <li parentName="ul">{`Follow an existing standard`}</li>
      <li parentName="ul">{`Create and release packages based on that standard without human intervention`}</li>
      <li parentName="ul">{`Provide our developers access to code as fast as possible`}</li>
    </ul>
    <p>{`To accomplish these goals, we used:`}</p>
    <ul>
      <li parentName="ul">{`Semver and Conventional Commits`}</li>
      <li parentName="ul">{`semantic-release`}</li>
      <li parentName="ul">{`Github Actions`}</li>
    </ul>
    <h3>{`Conventional Commits`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/"
      }}>{`Conventional Commits`}</a>{` is a specification for adding human and machine readable meaning to commit messages. For example, instead of writing `}<inlineCode parentName="p">{`added a Datepicker component`}</inlineCode>{`, following Conventional Commits you’d write `}<inlineCode parentName="p">{`feat: added a Datepicker component`}</inlineCode>{`. A commit can be designated a fix, feature, and/or a breaking change, which will correlate with Semver's `}<inlineCode parentName="p">{`patch`}</inlineCode>{`, `}<inlineCode parentName="p">{`minor`}</inlineCode>{`, and `}<inlineCode parentName="p">{`major`}</inlineCode>{` changes. This allows tooling to be built to automatically version and release packages.`}</p>
    <h3>{`semantic-release`}</h3>
    <p>{`The tooling we’re using is called `}<a parentName="p" {...{
        "href": "https://github.com/semantic-release/semantic-release"
      }}>{`semantic-release`}</a>{`, which “automates the whole package release workflow including:`}</p>
    <ul>
      <li parentName="ul">{`determining the next version number,`}</li>
      <li parentName="ul">{`generating the release notes,`}</li>
      <li parentName="ul">{`and publishing the package.”`}</li>
    </ul>
    <p>{`After ensuring commit messages are written with Conventional Commits, it’s possible to automatically package and release software through Continous Integration on every merge to master. This ensures your consumers are getting access to changes literally as fast as possible.`}</p>
    <p>{`To set this up:`}</p>
    <h4>{`Add semantic-release`}</h4>
    <pre><code parentName="pre" {...{}}>{`yarn add semantic-release --dev
`}</code></pre>
    <p><em parentName="p">{`Since semantic-release uses git tags and npm to decide on the package version, you'll want to set the `}<inlineCode parentName="em">{`version`}</inlineCode>{` in `}<inlineCode parentName="em">{`package.json`}</inlineCode>{` to something like `}<inlineCode parentName="em">{`0.0.0`}</inlineCode>{` to discourage manually updating it.`}</em></p>
    <h4>{`Add plugins`}</h4>
    <p>{`semantic-release can do a lot of things, so it's made up of opt-in plugins. We'd like it to do everything, so we'll use the following plugins, in order:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/commit-analyzer"
        }}>{`@semantic-release/commit-analyzer`}</a>{` to look through the commit message and decide what kind of change is being added.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/release-notes-generator"
        }}>{`@semantic-release/release-notes-generator`}</a>{` to generate release notes based on the commit message.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/changelog"
        }}>{`@semantic-release/changelog`}</a>{` to update a persistant `}<inlineCode parentName="li">{`CHANGELOG.md`}</inlineCode>{` file using those release notes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/git"
        }}>{`@semantic-release/git`}</a>{` to commit the changelog to the git repository.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/github"
        }}>{`@semantic-release/github`}</a>{` to tag and publish a Github release.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/semantic-release/npm"
        }}>{`@semantic-release/npm`}</a>{` to publish the package to npm.`}</li>
    </ul>
    <p>{`These can all be added with:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add --dev @semantic-release/changelog @semantic-release/commit-analyzer
@semantic-release/changelog @semantic-release/git @semantic-release/github
@semantic-release/npm
`}</code></pre>
    <p>{`Then tell semantic-release to use them, by adding a `}<inlineCode parentName="p">{`.releaserc.json`}</inlineCode>{` to the root directory with the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "plugins": [
    "@semantic-release/commit-analyzer",
    "@semantic-release/release-notes-generator",
    "@semantic-release/changelog",
    "@semantic-release/git",
    "@semantic-release/github",
    "@semantic-release/npm"
  ]
}
`}</code></pre>
    <p>{`Now that it's intsalled, we can run it on CI after our build.`}</p>
    <h3>{`Github Actions`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/features/actions"
      }}>{`Github Actions`}</a>{` is Github’s free Continous Integration service and will allow us to run semantic-release automatically on merge to master.`}</p>
    <h4>{`Add secret key`}</h4>
    <p>{`Generate an NPM secret key from your NPM account settings. Then go to the Settings > Secrets page in Github and add a token called `}<inlineCode parentName="p">{`NPM_TOKEN`}</inlineCode>{`.`}</p>
    <h4>{`Add workflow`}</h4>
    <p>{`Add a file called `}<inlineCode parentName="p">{`/.github/workflows/release.yml`}</inlineCode>{` with the following, replacing the command in the Build step with your relevant build command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yml"
      }}>{`name: Release
on:
  push:
    branches:
      - master
jobs:
  release:
    name: Release
    runs-on: ubuntu-18.04
    steps:
      - name: Checkout
        uses: actions/checkout@v2
        with:
          fetch-depth: 0
      - name: Setup Node.js
        uses: actions/setup-node@v1
        with:
          node-version: 12
      - name: Install dependencies
        run: yarn
      - name: Build
        run: yarn build
      - name: Release
        env:
          GITHUB_TOKEN: \${{ secrets.GITHUB_TOKEN }}
          NPM_TOKEN: \${{ secrets.NPM_TOKEN }}
        run: npx semantic-release
`}</code></pre>
    <p>{`That's it. Automated releases with changelogs published on every merge to master with no human intervention.`}</p>
    <p>{`A test repo of this code can be found at `}<a parentName="p" {...{
        "href": "https://github.com/nulogy/nds-test-releases"
      }}>{`https://github.com/nulogy/nds-test-releases`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      